import React from 'react'
import { LIGHT_GREEN, ACQUA, LIGHT_GRAY, BLUE } from '../../constants/Colors';
import styled from 'styled-components'
import { FONT_FAMILY_2 } from '../../constants/FontFamily';

const Button = styled.button`
    background-color: ${LIGHT_GREEN};
    border: none;
    border-radius: 5px;
    line-height: 35px;
    font-family: ${FONT_FAMILY_2};
    font-size: 16px;
    transition: background-color 250ms ease-out;
    outline: none;
    font-weight: 700;
    padding: 3px 20px;
    box-shadow: 0px 2px 5px 0 rgba(54, 45, 43, 0.5);
    cursor: pointer;

    &:hover{
        background-color: ${ACQUA};
    }

    &:active{
        background-color: ${BLUE};
    }

    &:disabled{
        background-color: ${LIGHT_GRAY};
    }

`

const ButtonContainer = ({children, ...props}) => (
    <Button {...props}>{children}</Button>
);

export default ButtonContainer