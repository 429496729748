import React from 'react'
import { Link } from 'gatsby'
import Heading from '../components/typograph/Heading'
import Text from '../components/typograph/Text'
import MenuButton from '../components/buttons/MenuButton'
import Button from '../components/buttons/Button'

const TestePage = () => (
  <div>
    <Heading type="h1">Hi people 1</Heading>
    <Heading type="h2">Hi people 2 </Heading>
    <Heading type="h3">Hi people 3</Heading>
    <Heading type="h4">Hi people 4</Heading>
    <Text type="p">Welcome to your new Gatsby site.</Text>
    <Text type="highlight">Welcome to your new Gatsby site.</Text>
    <span>. </span> <MenuButton />
    <br /> <br />
    <Link to="/page-2/">Go to page 2</Link>
    <br />
    <Button>TESTE</Button>
  </div>
)

export default TestePage
